import React, { Component } from 'react';
import Layout from '../layouts/nl';
// import { Link } from 'gatsby';
// import jobDetail from '../assets/images/job-detail-image.jpg';
import iconDownBlue from '../assets/images/icons/icon-down-blue.svg';
import iconCaretRight from '../assets/images/icons/icon-caret-right.svg';
import axios from 'axios';
import Scroll from './../components/Scroll';
import showdown from 'showdown';

export default class JobsPage extends Component {
  apiEndPoint = process.env.GATSBY_API_URL;

  state = {};
  toggleTab = true;

  constructor(props) {
    super(props);
    this.state = {
      portfolio: null,
      acc: null,
      jobs: null,
      toggleTab: true,
    };
    this.getAllJobs();
  }

  showTab() {
    const toggleTab = this.state.toggleTab;
    this.setState({ toggleTab: !toggleTab });
  }

  toggleSection(section) {
    const jobs = this.state.jobs;
    this.state.jobs.forEach((e, i) => {
      if (section !== i) {
        jobs[i]['expanded'] = false;
      }
    });

    jobs[section]['expanded'] = !jobs[section]['expanded'];

    this.setState({ jobs: jobs });

    setTimeout(() => {
      const elem = document.getElementById('expanded');

      if (elem !== null) {
        if (typeof window !== 'undefined') {
          const elemPos = elem
            ? elem.getBoundingClientRect().top + window.pageYOffset
            : 0;
          window.scroll({ top: elemPos - 100, left: 0, behavior: 'smooth' });
        }
      }
    }, 300);
  }

  /**
   * Get all jobs from response.
   */
  getAllJobs() {
    axios
      .get(`${this.apiEndPoint}/jobsnls?active=true`)
      .then(response => {
        let portfolio = [];
        let acc = [];
        response.data.forEach((e, i) => {
          e.expanded = false;
          if (e.category === 'acc') {
            acc.push(e);
          } else if (e.category === 'portfolio') {
            portfolio.push(e);
          }
        });

        this.setState({
          jobs: response.data,
          acc: acc,
          portfolio: portfolio,
        });
      })
      .catch(err => {
        // console.error(err);
      })
      .finally(() => {});
  }

  render() {
    const converter = new showdown.Converter();
    return (
      <Layout>
        <header id="header" className="masthead jobs-wrapper"></header>
        <div className="align-items-center jobs-white-container">
          <div className="mx-auto text-center">
            <div className="jobs-container">
              <div className="approach-wrapper">
                <h3 className="jobs-desktop-text">Onze vacatures</h3>
                <div className="mobile-jobs-text">
                  <span className="gray-label text-left">
                    Werken bij Active Capital Company
                  </span>
                  <h4 className="text-left">
                    Wij zijn een team van getalenteerde en energieke
                    professionals met een hands-on mentaliteit om elke dag meer
                    waarde te creëren voor onze investeringen.
                  </h4>
                  <Scroll type="id" element="jobs-start">
                    <a to="#jobs-start" className="blue-color-link text-left">
                      <img src={iconDownBlue} alt="" />
                      Bekijk onze open vacatures
                    </a>
                  </Scroll>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jobs-container jobs-custom-wrapper" id="jobs-start">
          <div className="top-separator heading-and-tabs">
            <div className="jobs-narrow-container">
              <h3 className="mobile-jobs-text">Onze vacatures</h3>
              <div className="tabs-wrapper">
                <div
                  className={`tab-link${this.state.toggleTab ? ' active' : ''}`}
                  onClick={() => this.showTab()}
                >
                  ACC Vacatures
                </div>
                {/* <span className="vertical-separator"></span> */}
                {/* <div className={`tab-link${!this.state.toggleTab ? ' active' : ''}`} onClick={() => this.showTab()}>Portfolio Vacatures</div> */}
              </div>
              <div className="clearfix"></div>
              <span className="horizontal-line gray-background no-side-margin team-line"></span>
            </div>

            {
              /*
                            (this.state.jobs === undefined || this.state.jobs === null || this.state.jobs.length === 0) ?
                                (
                                    <div className={`open-jobs-wrapper`}>
                                        No Jobs available.
                                    </div>
                                ) :
                                */
              <div className="open-jobs-wrapper">
                {this.state.toggleTab ? (
                  this.state.acc !== undefined &&
                  this.state.acc !== null &&
                  this.state.acc.length !== 0 ? (
                    this.state.acc.map((item, index) => (
                      <div
                        className={`job-wrapper${
                          this.state.jobs[index].expanded ? ' opened' : ''
                        }`}
                        id={
                          this.state.jobs[index].expanded ? 'expanded' : index
                        }
                      >
                        <div
                          className="job-toggle-wrapper"
                          onClick={() => this.toggleSection(index)}
                        >
                          <h4 className="job-title">{item.job_title}</h4>
                          <div className="job-info">
                            <span className="job-type">Full time</span>
                            <span className="vertical-separator"></span>
                            <span className="job-position">
                              {item.city}, {item.country}
                            </span>
                            <span
                              className={`job-caret${
                                this.state.jobs[index].expanded ? ' opened' : ''
                              }`}
                            ></span>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div
                          className={`job-details${
                            this.state.jobs[index].expanded ? '' : ' hide'
                          }`}
                        >
                          <div
                            className={`${
                              item.testimony_image !== null
                                ? 'col-md-6'
                                : 'col-md-12'
                            } float-left jobs-text-wrapper`}
                          >
                            <span className="black-color">
                              <div
                                className="custom-editor-styles custom-jobs-editor-styles"
                                dangerouslySetInnerHTML={{
                                  __html: converter.makeHtml(item.job_text),
                                }}
                              />
                            </span>
                            <br className="mobile-hidden" />
                            <br />
                            {/* <button className="button button-blue bottom-margin mail-button">
                              <a
                                href={`mailto:spigt@activecapitalcompany.com?subject=${item.job_title +
                                  ' - ' +
                                  item.city +
                                  ' - ' +
                                  item.country}`}
                                className="blue-color mail-link"
                              >
                                Solliciteer direct
                                <img src={iconCaretRight} alt="" />
                              </a>
                            </button> */}
                          </div>

                          {item.testimony_image !== null &&
                          item.testimony_image !== undefined ? (
                            <div className="col-md-6 float-left jobs-image-wrapper">
                              <div className="relative">
                                <img
                                  className="medium-darkened full-width"
                                  src={
                                    this.apiEndPoint + item.testimony_image.url
                                  }
                                  alt="Job detail"
                                />
                                <div className="job-image-description">
                                  <h5 className="white-color">
                                    {item.employee_testimony}
                                  </h5>
                                  <p className="orange-color">
                                    Dennis Van De Laar - Investment Analyst
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={`open-jobs-wrapper jobs-narrow-container`}>
                      Helaas zijn er momenteel geen open vacatures.
                    </div>
                  )
                ) : this.state.portfolio !== undefined &&
                  this.state.portfolio !== null &&
                  this.state.portfolio.length !== 0 ? (
                  this.state.portfolio.map((item, index) => (
                    <div
                      className={`job-wrapper${
                        this.state.jobs[index].expanded ? ' opened' : ''
                      }`}
                      id={this.state.jobs[index].expanded ? 'expanded' : index}
                    >
                      <div
                        className="job-toggle-wrapper"
                        onClick={() => this.toggleSection(index)}
                      >
                        <h4 className="job-title">{item.job_title}</h4>
                        <div className="job-info">
                          <span className="job-type">Full time</span>
                          <span className="vertical-separator"></span>
                          <span className="job-position">
                            {item.city}, {item.country}
                          </span>
                          <span
                            className={`job-caret${
                              this.state.jobs[index].expanded ? ' opened' : ''
                            }`}
                          ></span>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div
                        className={`job-details${
                          this.state.jobs[index].expanded ? '' : ' hide'
                        }`}
                      >
                        <div
                          className={`${
                            item.testimony_image !== null
                              ? 'col-md-6'
                              : 'col-md-12'
                          } float-left jobs-text-wrapper`}
                        >
                          <span className="black-color">
                            <div
                              className="custom-editor-styles"
                              dangerouslySetInnerHTML={{
                                __html: converter.makeHtml(item.job_text),
                              }}
                            />
                          </span>
                          <br className="mobile-hidden" />
                          <br />
                          {/* <button className="button button-blue bottom-margin mail-button">
                            <a
                              href={`mailto:spigt@activecapitalcompany.com?subject=${item.job_title +
                                ' - ' +
                                item.city +
                                ' - ' +
                                item.country}`}
                              className="blue-color mail-link"
                            >
                              Solliciteer direct
                              <img src={iconCaretRight} alt="" />
                            </a>
                          </button> */}
                        </div>

                        {item.testimony_image !== null &&
                        item.testimony_image !== undefined ? (
                          <div className="col-md-6 float-left jobs-image-wrapper">
                            <div className="relative">
                              <img
                                className="medium-darkened full-width"
                                src={
                                  this.apiEndPoint + item.testimony_image.url
                                }
                                alt="Job detail"
                              />
                              <div className="job-image-description">
                                <h5 className="white-color">
                                  {item.employee_testimony}
                                </h5>
                                <p className="orange-color">
                                  Dennis Van De Laar - Investment Analyst
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className={`open-jobs-wrapper jobs-narrow-container`}>
                    Helaas zijn er momenteel geen open vacatures.
                  </div>
                )}
              </div>
            }

            <span className="horizontal-line gray-background no-side-margin top-separator thin-border"></span>
          </div>
          <Scroll type="id" element="header">
            <div className="back-to-top-wrapper">
              <a href="?scroll-to=header">
                <i className="fas fa-angle-double-up"></i>
                <br />
                <span className="back-to-top">Terug naar boven</span>
              </a>
            </div>
          </Scroll>
        </div>
      </Layout>
    );
  }
}
